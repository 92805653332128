import { template as template_7a7a4cc2a78f4b2c8de3ec88bc923dfa } from "@ember/template-compiler";
const FKControlMenuContainer = template_7a7a4cc2a78f4b2c8de3ec88bc923dfa(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
