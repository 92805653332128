import { template as template_1d02e3bedbeb4857bd8ae21a73a760c3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_1d02e3bedbeb4857bd8ae21a73a760c3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
